import {arrToLookup, arrToMap, Lookup, MapFunc} from "@juulsgaard/ts-tools";

declare global {
  interface Array<T> {
    toggleItem(item: T, state?: boolean): boolean;
    toMap<TKey>(selector: MapFunc<T, TKey>): Map<TKey, T>;
    toMap<TKey, TVal>(getKey: MapFunc<T, TKey>, getVal: MapFunc<T, TVal>): Map<TKey, TVal>;
    toSet(): Set<T>;
    groupBy<TKey>(selector: MapFunc<T, TKey>): Lookup<TKey, T>;
    groupBy<TKey, TVal>(getKey: MapFunc<T, TKey>, getVal: MapFunc<T, TVal>): Lookup<TKey, TVal>;
  }
}

Array.prototype.toggleItem = function<T> (this: T[], item: T, state?: boolean) {
  const index = this.indexOf(item);

  if (index >= 0) {
    if (state === true) return false;
    this.splice(index, 1);
    return true;
  }

  if (state === false) return false;
  this.push(item);
  return true;
}

Array.prototype.toMap = function<T, TKey, TVal> (this: T[], getKey: MapFunc<T, TKey>, getVal?: MapFunc<T, TVal>) {
  return getVal ? arrToMap(this, getKey, getVal) : arrToMap(this, getKey);
}

Array.prototype.toSet = function<T> (this: T[]) {
  return new Set<T>(this);
}

Array.prototype.groupBy = function<T, TKey, TVal>(this: T[], getKey: MapFunc<T, TKey>, getVal?: MapFunc<T, TVal>): Lookup<TKey, T|TVal> {
  return getVal ? arrToLookup(this, getKey, getVal) : arrToLookup(this, getKey);
}

export {};
