import {Component, NgZone} from '@angular/core';
import {PageSearchService} from "@core/services/page-search.service";
import {fromEvent} from "rxjs";
import {filter} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-page-search-outlet',
  templateUrl: './page-search-controller.component.html',
  styleUrls: ['./page-search-controller.component.scss']
})
export class PageSearchControllerComponent {

  lastPress?: Date;

  isActive = false;
  isOpen = false;

  constructor(public service: PageSearchService, private zone: NgZone) {

    zone.runOutsideAngular(() => {
      fromEvent<KeyboardEvent>(window, 'keydown').pipe(
        filter(() => this.isActive),
        filter(() => document.activeElement === document.body),
        filter(x => !x.repeat),
        filter(e => e.key === 'Shift'),
        takeUntilDestroyed()
      ).subscribe(() => this.onShift())
    });

    this.service.hasConfig$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.isActive = x;
      if (!x) this.isOpen = false;
    });
  }

  onShift() {
    const now = new Date();
    if (this.lastPress) {
      const diff = now.getTime() - this.lastPress.getTime();
      if (diff < 500) {
        this.open();
        this.lastPress = undefined;
        return;
      }
    }

    this.lastPress = now;
  }

  open() {
    if (!this.isActive) return;
    this.zone.run(() => this.isOpen = true);
  }

}
