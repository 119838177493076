import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {CoreModule} from "@core/core.module";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {authInterceptor} from "@core/interceptors/auth.interceptor";
import {urlInterceptor} from "@core/interceptors/url.interceptor";
import {RouterModule} from "@angular/router";
import {appRoutes} from "./app.routes";
import {environment} from "@environments/environment";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LocaleService} from "@core/services/locale.service";
import {ServiceWorkerModule} from '@angular/service-worker';

import {IndexedDbAdapter} from '@juulsgaard/store-service';
import {NgxServiceWorkerModule} from '@juulsgaard/ngx-service-worker';
import {UserCacheContext} from "@core/databases/user-cache-context";
import {
  NgxDialogOutletModule, NgxOverlayOutletModule, NgxSideMenuOutletModule, NgxSnackbarOutletModule, UIScopeContext
} from "@juulsgaard/ngx-material";
import {IconProviderModule, IdManagerService} from "@juulsgaard/ngx-tools";
import {uiScopeConfig} from "@lib/models/ui-scopes";
import {AsideService} from "@lib/services/aside.service";
import {IdManager} from "@app-lib/services/id-manager.service";
import {FormConfirmService, InputTypes} from "@juulsgaard/ngx-forms-core";
import {DialogFormConfirmService} from "@lib/services/dialog-form-confirm.service";
import {exceptionInterceptor} from "@core/interceptors/exception.interceptor";
import {DefaultFormInputRegistryService} from "@juulsgaard/ngx-forms-inputs";
import {HtmlInputComponent} from "@forms/components/html-input/html-input.component";
import {MatAnchor} from "@angular/material/button";
import {MatRipple} from "@angular/material/core";
import {MatTooltip} from "@angular/material/tooltip";

const devImports: any[] = [];

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    RouterModule.forRoot(appRoutes),
    ...devImports,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxServiceWorkerModule.register(environment.serviceWorker),
    IconProviderModule.WithFontAwesome(),
    NgxDialogOutletModule,
    NgxSideMenuOutletModule,
    NgxOverlayOutletModule.WithBaseProviders(UIScopeContext.Provide(uiScopeConfig), {provide: AsideService}),
    NgxSnackbarOutletModule,
    MatAnchor,
    MatRipple,
    MatTooltip
  ],
  providers: [
    UIScopeContext.Provide(uiScopeConfig),
    LocaleService.Provide(),
    {provide: IndexedDbAdapter, useValue: new IndexedDbAdapter()},
    UserCacheContext,
    IdManagerService.Provide(IdManager),
    FormConfirmService.Provide(DialogFormConfirmService),
    DefaultFormInputRegistryService.Provide(c => c.register(InputTypes.HTML, HtmlInputComponent)),
    provideHttpClient(withInterceptors([
      exceptionInterceptor,
      authInterceptor,
      urlInterceptor
    ]))
  ]
})
export class AppModule {}
