import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {asyncScheduler, BehaviorSubject, combineLatest, Observable, of, switchMap} from "rxjs";
import {IPageSearchConfig, PageSearchItem} from "@core/models/page-search.models";
import {map, throttleTime} from "rxjs/operators";
import {sortNumAsc} from "@juulsgaard/ts-tools";
import {persistentCache} from "@juulsgaard/rxjs-tools";
import {Dispose, OverlayService, OverlayToken} from "@juulsgaard/ngx-tools";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-page-search',
  templateUrl: './page-search.component.html',
  styleUrls: ['./page-search.component.scss']
})
export class PageSearchComponent implements AfterViewInit, OnInit {

  @ViewChild('input', {static: true}) inputElement?: ElementRef<HTMLInputElement>;

  @Output() close = new EventEmitter<void>();
  configs: IPageSearchConfig<any>[] = [];
  @Input('configs') set configData(configs: IPageSearchConfig<any>[]|null) {
    this.configs = configs ?? [];
  }

  @Dispose private token?: OverlayToken;

  query$ = new BehaviorSubject('');

  tabs: Tab<any>[] = [];
  tabIndex$ = new BehaviorSubject(0);
  itemIndex = 0;
  results: TabItem<any>[] = [];

  tab$: Observable<Tab<any>|undefined>;

  constructor(private overlayService: OverlayService, private router: Router) {
    this.tab$ = this.tabIndex$.pipe(map(i => this.tabs[i]));

    this.tab$.pipe(
      switchMap(x => x?.data$ ?? of([])),
      takeUntilDestroyed()
    ).subscribe(data => {
      this.results = data;
      if (data.length < 1) return;
      this.itemIndex = 0;
    });
  }

  ngOnInit() {
    this.token = this.overlayService.pushOverlay();
    this.token.escape$.subscribe(() => this.close.emit());

    this.setupTabs();
    this.tabIndex$.next(0);
  }

  ngAfterViewInit() {
    this.inputElement?.nativeElement.focus();
  }

  private setupTabs() {

    const query$ = this.query$.pipe(
      throttleTime(500, asyncScheduler, {leading: true, trailing: true}),
      persistentCache()
    );

    const tabs: Tab<any>[] = this.configs.reverse().map(pageConfig => {

      const data$ = pageConfig.subscribe(query$).pipe(
        map(items => items.map(x => ({
          ...x,
          onClick: !pageConfig.onClick ? undefined : (hasRoute: boolean) => pageConfig.onClick?.(x.model, hasRoute),
          route: !pageConfig.route ? undefined : pageConfig.route?.(x.model),
          routeRelativeTo: pageConfig.routeRelativeTo,
          icon: x.icon ?? pageConfig.fallbackIcon ?? 'far fa-question'
        } as TabItem<any>)))
      );

      return {
        name: pageConfig.name,
        data$: query$.pipe(
          switchMap(q => q.length ? data$ : of([]))
        ),
      };
    });

    if (tabs.length < 2) {
      this.tabs = tabs;
      return;
    }

    const allTab: Tab<any> = {
      name: 'All',
      data$: combineLatest(tabs.map(x => x.data$)).pipe(
        map(x => ([] as TabItem<any>[]).concat(...x)),
        map(x => x.sort(sortNumAsc(x => x.score))),
        map(x => x.slice(0, 20))
      )
    };

    this.tabs = [allTab, ...tabs];
  }

  onClick(item: TabItem<any>) {
    item.onClick?.(!!item.route);
    this.close.emit();
  }

  clickTab(index: number) {
    this.tabIndex$.next(index);
  }

  noFocus(event: MouseEvent) {
    event.preventDefault();
  }

  keyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Tab':
        this.onTab(event.shiftKey);
        break;
      case 'ArrowUp':
        this.arrowUp();
        break;
      case 'ArrowDown':
        this.arrowDown();
        break;
      case 'Enter':
        this.onEnter();
        break;
      default:
        return;
    }

    event.preventDefault();
  }

  private onTab(goBack = false) {
    if (this.tabs.length < 2) return;
    const newVal = this.tabIndex$.value + (goBack ? -1 : 1);
    this.tabIndex$.next((newVal + this.tabs.length) % this.tabs.length);
  }

  private arrowUp() {
    if (this.results.length < 2) return;
    this.itemIndex = (this.itemIndex - 1 + this.results.length) % this.results.length;
  }

  private arrowDown() {
    if (this.results.length < 2) return;
    this.itemIndex = (this.itemIndex + 1) % this.results.length;
  }

  private onEnter() {
    const item = this.results[this.itemIndex];
    if (!item) return;
    if (item.route) {
      this.router.navigate(item.route, {relativeTo: item.routeRelativeTo});
    }
    this.onClick(item);
  }
}

interface Tab<TModel> {
  name: string;
  data$: Observable<TabItem<TModel>[]>;
}

interface TabItem<TModel> extends PageSearchItem<TModel> {
  onClick?: (hasRoute: boolean) => void;
  route?: string[];
  routeRelativeTo?: ActivatedRoute;
  icon: string;
}
