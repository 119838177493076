import {mapToArr} from "@juulsgaard/ts-tools";

declare global {
  interface Map<K, V> {
    map<TRes>(map: (val: V, key: K) => TRes): TRes[];
    toArray(): [K, V][];
  }
}

Map.prototype.map = function<K, V, TRes>(this: Map<K, V>, map: (val: V, key: K) => TRes) {
  return mapToArr(this, map);
}

Map.prototype.toArray = function<K, V>() {
  return Array.from(this.entries());
}

export {};
