import {Component, OnInit} from '@angular/core';
import {FileUploadService} from "@core/services/file-upload.service";

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent implements OnInit {

  constructor(public uploadService: FileUploadService) { }

  ngOnInit(): void {
  }

}
