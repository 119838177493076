import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {SessionService} from "@core/services/session.service";
import {AuthTokenService} from "@core/services/auth-token.service";

export const notAuthenticatedGuard: CanActivateFn = async function () {
  const router = inject(Router);
  const tokenService = inject(AuthTokenService);
  const sessionService = inject(SessionService);

  console.debug('# Not Authenticated Guard');

  if (sessionService.authenticated()) {
    console.debug('User Authenticated - Redirecting to CMS');
    return router.parseUrl('/cms');
  }

  if (tokenService.refreshToken) {
    console.debug('User has Token - Redirecting to CMS');
    return router.parseUrl('/cms');
  }

  console.debug('Not Authenticated');
  return true;
}
