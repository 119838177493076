import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {SessionService} from "@core/services/session.service";
import {AuthException} from "@lib/exceptions/auth.exception";
import {AuthTokenService} from "@core/services/auth-token.service";
import {sleep} from "@juulsgaard/ts-tools";
import {AuthStore} from "@core/stores/auth/auth.store";

export const authenticatedGuard: CanActivateFn = async function () {
  const router = inject(Router);
  const tokenService = inject(AuthTokenService);
  const sessionService = inject(SessionService);
  const store = inject(AuthStore);

  console.debug('# Authenticated Guard');

  if (sessionService.authenticated()) {
    console.debug(`User is Authenticated`);
    return true;
  }

  if (!tokenService.refreshToken) {
    console.debug(`No refresh token - Sending to login`);
    return router.parseUrl('/login');
  }

  // Force new Auth Token
  tokenService.clearAuthToken();

  for (let i = 0; i < 5; i++) {

    console.debug(`Attempting to login - Attempt #${i + 1}`);

    try {

      await store.loadUser.emitAsync();

      console.debug(`Logged in user`);
      return true;

    } catch (e) {

      if (e instanceof AuthException) {

        console.debug(`No refresh token - Sending to login`);

        if (e.loggedOut) return false;

        sessionService.logOut();
        return router.parseUrl('/login');
      }

      await sleep(5000);
    }
  }

  console.debug(`Login failed too many times - Redirecting to error page`);
  await router.navigate(['error'], {skipLocationChange: true, queryParams: {reason: 'Authentication Failed'}});
  return false;
}
