import {Component} from '@angular/core';

@Component({
  selector: 'app-empty',
  template: ''
})
export class EmptyComponent {

  constructor() { }
}
