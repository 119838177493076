import {Routes} from "@angular/router";
import {rootGuard} from "@core/guards/root.guard";
import {ErrorPageComponent} from "@core/components/error-page/error-page.component";
import {notAuthenticatedGuard} from "@core/guards/not-authenticated.guard";
import {authenticatedGuard} from "@core/guards/authenticated.guard";
import {PageNotFoundComponent} from "@core/components/page-not-found/page-not-found.component";
import {EmptyComponent} from "@core/components/empty/empty.component";

export const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [rootGuard],
        component: EmptyComponent
    },
    {
        path: 'error',
        component: ErrorPageComponent,
    },
    {
        path: 'cms',
        loadChildren: () => import('@cms/root/root-cms/root-cms.module').then(x => x.RootCmsModule),
        canActivate: [authenticatedGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('src/app/login/login.module').then(x => x.LoginModule),
        canActivate: [notAuthenticatedGuard]
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];
