
<div class="background" (click)="close.emit()"></div>

<div class="overlay-content">
    <div class="header">
        <i class="close far fa-times" matRipple (click)="close.emit()"></i>
        <div class="tabs" *ngIf="tabs.length > 1">
            <div class="tab" *ngFor="let t of tabs; let i = index" [class.active]="i === tabIndex$.value" (mousedown)="noFocus($event)" (click)="clickTab(i)" >{{t.name}}</div>
        </div>
        <p *ngIf="tabs.length <= 1">Search on page</p>
    </div>
    <div class="input">
        <i class="far fa-search"></i>
        <input type="text" #input [ngModel]="query$.value" (ngModelChange)="query$.next($event)" (blur)="close.emit()" (keydown)="keyDown($event)">
    </div>
    <div class="results" *ngIf="tab$ | async as tab">
        <a class="item" *ngFor="let item of tab.data$ | async; let i = index" [class.active]="i === itemIndex" matRipple (mousedown)="noFocus($event)" (click)="onClick(item)" [routerLink]="item.route" [relativeTo]="item.routeRelativeTo">
            <i class="icon" [ngClass]="item.icon"></i>
            <p class="name">{{item.name}}</p>
            <p class="extra" *ngIf="item.extra">{{item.extra}}</p>
        </a>
    </div>
</div>
