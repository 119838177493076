import {Component} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(router: Router) {

    router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe(e => {
      const event = e as NavigationEnd;
      console.debug(`%cNavigated to:  ${event.url}`, 'color: #999');
    });

    router.events.pipe(filter(x => x instanceof NavigationStart)).subscribe(e => {
      const event = e as NavigationStart;
      console.debug(`%cNavigating to: ${event.url}`, 'color: #666');
    });

  }
}
