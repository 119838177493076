import {Injectable} from '@angular/core';
import {IdManagerService} from "@juulsgaard/ngx-tools";
import {SnackbarService} from "@juulsgaard/ngx-material";

@Injectable()
export class IdManager extends IdManagerService {

  constructor(private snacks: SnackbarService) {
    super();
  }

  protected onCopied(): void {
    this.snacks.success('Id copied to Clipboard');
  }
}
