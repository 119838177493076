import {HttpInterceptorFn} from "@angular/common/http";
import {from} from "rxjs";
import {AuthTokenService} from "../services/auth-token.service";
import {switchMap} from "rxjs/operators";
import {inject} from "@angular/core";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  if (!req.url.startsWith('api://')) {
    return next(req);
  }

  const service = inject(AuthTokenService);

  return from(service.resolveAuthToken()).pipe(
    switchMap(token => next(
      req.clone({setHeaders: {'Authorization': `Bearer ${token}`}})
    ))
  );
}
