import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '@environments/environment';
import Prism from 'prismjs';

import '@lib/extensions/index';

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import localeData from 'dayjs/plugin/localeData'


dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(localeData);

Prism.manual = true;

const locales = ['en', 'da'];

function startup() {

  if (environment.production) {

    const pathname = location.pathname.replace(/^\/+/, '');
    if (pathname.length > 0) {

      const regex = new RegExp(`^(${locales.join('|')})(\/|$)`, 'i')
      const found = regex.test(pathname);

      if (!found) {
        const locale = getLocale();
        location.pathname = `/${locale}/${pathname}`;
        return;
      }
    } else {
      const locale = getLocale();
      location.pathname = `/${locale}`;
      return;
    }

    enableProdMode();

  } else {
    environment.apiHost = environment.apiHost.replace('localhost', location.hostname);
    environment.embeddedUrl = environment.embeddedUrl.replace('localhost', location.hostname);
    environment.storageUrl = environment.storageUrl.replace('localhost', location.hostname);
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

function getLocale() {
  let locale = localStorage.getItem('locale');

  if (locale) {
    if (!locales.includes(locale)) locale = null;
  }

  if (!locale) {
    locale = getDefaultLocale() ?? null;
    if (locale) localStorage.setItem('locale', locale);
    else localStorage.removeItem('locale');
  }

  return locale;
}

function getDefaultLocale() {
  const language = (navigator.languages?.[0] ?? navigator.language).toLowerCase();
  for (let locale of locales) {
    if (language.startsWith(locale)) return locale;
  }

  return locales[0];
}

//Stop file drops
window.addEventListener('drop', e => e.preventDefault());
window.addEventListener('dragover', e => e.preventDefault());

startup();
