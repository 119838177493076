import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router} from '@angular/router';
import {SessionService} from "@core/services/session.service";
import {AuthTokenService} from "@core/services/auth-token.service";

export const rootGuard: CanActivateFn = async function (route: ActivatedRouteSnapshot) {
  const router = inject(Router);
  const tokenService = inject(AuthTokenService);
  const sessionService = inject(SessionService);

  console.debug('# Root Guard');

  const authToken = route.queryParams['auth'];
  if (authToken) {

    console.debug('Auth Token Found - Logging out and redirecting to Login');

    if (tokenService.refreshToken) {
      tokenService.clearTokens();
    }

    if (sessionService.authenticated()) {
      sessionService.logOut();
    }

    const url = router.parseUrl('/login');
    url.queryParams = {auth: authToken};
    return url;
  }

  if (sessionService.authenticated()) {
    console.debug('User Authenticated - Redirecting to CMS');
    return router.parseUrl('/cms');
  }

  if (tokenService.refreshToken) {
    console.debug('User has Token - Redirecting to CMS');
    return router.parseUrl('/cms');
  }

  console.debug('Not Authenticated - Redirecting to login');
  return router.parseUrl('/login');
};
