import {HttpErrorResponse, HttpInterceptorFn} from "@angular/common/http";
import {throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {ApiException} from "@lib/exceptions/api.exception";

export const exceptionInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError(e => throwError(() => parseError(e)))
  );
};

function parseError(error: any) {
  if (error instanceof HttpErrorResponse) {
    return new ApiException(
      error.error?.error ?? 'Something went wrong',
      error.status,
      error.headers.get('x-correlation-id') ?? undefined
    );
  }

  return new ApiException('Something went wrong', 500);
}
