import {
  camelCase, dashCase, lowerFirst, pascalCase, slugify, stripTags, titleCase, upperFirst
} from '@juulsgaard/ts-tools';

declare global {
  interface String {
    slugify(): string;
    lowerFirst<T extends string>(this: T): Uncapitalize<T>;
    upperFirst<T extends string>(this: T): Capitalize<T>;
    toCamelCase(): string;
    toPascalCase(): string;
    toDashCase(): string;
    toTitleCase(): string;
    stripTags(): string;
  }
}

String.prototype.slugify = function(this: string) {
  return slugify(this);
}

String.prototype.lowerFirst = function<T extends string>(this: T) {
  return lowerFirst(this);
}

String.prototype.upperFirst = function<T extends string>(this: T) {
  return upperFirst(this);
}

String.prototype.toPascalCase = function(this: string) {
  return pascalCase(this);
}

String.prototype.toCamelCase = function(this: string) {
  return camelCase(this);
}

String.prototype.toDashCase = function(this: string) {
  return dashCase(this)
}

String.prototype.toTitleCase = function(this: string) {
  return titleCase(this);
}

String.prototype.stripTags = function(this: string) {
  return stripTags(this);
}


export {};

