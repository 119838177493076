import {Form, FormConstants, FormConstructors} from "@juulsgaard/ngx-forms-core";

declare module '@juulsgaard/ngx-forms-core' {
  interface FormConstructors {
    serverNull: ServerNullConstructors;
  }
}

class ServerNullConstructors {

  color() {
    return Form.nullable.color(undefined, {fallback: FormConstants.NULL_STRING, disabledFallback: undefined});
  }

  text() {
    return Form.nullable.text(undefined, {fallback: FormConstants.NULL_STRING, disabledFallback: undefined});
  }

  longText() {
    return Form.nullable.longText(undefined, {fallback: FormConstants.NULL_STRING, disabledFallback: undefined});
  }

  html() {
    return Form.nullable.html(undefined, {fallback: FormConstants.NULL_STRING, disabledFallback: undefined});
  }

  url() {
    return Form.nullable.url(undefined, {fallback: FormConstants.NULL_STRING, disabledFallback: undefined});
  }

  guid() {
    return Form.nullable.guid(undefined, {fallback: FormConstants.NULL_GUID, disabledFallback: undefined});
  }

}

FormConstructors.prototype.serverNull = new ServerNullConstructors();
