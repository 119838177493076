import {NgModule} from "@angular/core";
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {EmptyComponent} from './components/empty/empty.component';
import {CommonModule} from "@angular/common";
import {UploadProgressComponent} from './components/upload-progress/upload-progress.component';
import {PageSearchControllerComponent} from './components/page-search-controller/page-search-controller.component';
import {PageSearchComponent} from './components/page-search-controller/page-search/page-search.component';
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgxServiceWorkerModule} from "@juulsgaard/ngx-service-worker";
import {FileSizePipe} from "@juulsgaard/ngx-tools";
import {MatRippleModule} from "@angular/material/core";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";


@NgModule({
  imports: [
    MatSnackBarModule,
    MatButtonModule,
    CommonModule,
    MatProgressBarModule,
    FormsModule,
    MatRippleModule,
    RouterModule,
    NgxServiceWorkerModule,
    FileSizePipe,
  ],
  declarations: [
    ErrorPageComponent,
    PageNotFoundComponent,
    EmptyComponent,
    UploadProgressComponent,
    PageSearchControllerComponent,
    PageSearchComponent,
  ],
  exports: [
    UploadProgressComponent,
    PageSearchControllerComponent,
  ],
  providers: []
})
export class CoreModule {

}
