import {
  ListDataSource, ListRange, ListSelection, ListState, TreeDataSource, TreeItemState, TreeRange, TreeSelection,
  TreeState
} from '@juulsgaard/data-sources';
import {WithId} from "@juulsgaard/ts-tools";
import {RouteService} from "@juulsgaard/ngx-tools";
import {assertInInjectionContext, assertNotInReactiveContext, effect, inject, untracked} from "@angular/core";

//<editor-fold desc="List Source">
declare module '@juulsgaard/data-sources' {
  interface ListDataSource<TModel> {
    itemFromParam(param: string): ListState<TModel>;
    createSelection(): ListSelection<TModel>;
    createRangeSelection(): ListRange<TModel>;
    setItemsComputed(compute: () => TModel[]): void;
  }
}

ListDataSource.prototype.itemFromParam = function<TModel>(param: string) {

  const locationService = inject(RouteService);
  const itemId$ = locationService.getParam$(param);
  return new ListState(this, itemId$);
}

ListDataSource.prototype.createSelection = function<TModel extends WithId>() {
  return new ListSelection<TModel>(this);
}

ListDataSource.prototype.createRangeSelection = function<TModel extends WithId>() {
  return new ListRange<TModel>(this);
}

ListDataSource.prototype.setItemsComputed = function<TModel extends WithId>(compute: () => TModel[]) {
  assertInInjectionContext(ListDataSource.prototype.setItemsComputed);
  assertNotInReactiveContext(ListDataSource.prototype.setItemsComputed);
  effect(() => {
    const values = compute();
    untracked(() => this.setItems(values));
  });
}
//</editor-fold>

//<editor-fold desc="Tree Source">
declare module '@juulsgaard/data-sources' {
  interface TreeDataSource<TFolder, TItem> {
    stateFromParams(folderParam?: string, itemParam?: string): TreeState<TFolder, TItem>;
    itemStateFromParams(itemParam?: string): TreeItemState<TFolder, TItem>;
    createState(): TreeState<TFolder, TItem>;
    createItemState(): TreeItemState<TFolder, TItem>;
    createSelection(): TreeSelection<TFolder, TItem>;
    createRange(): TreeRange<TFolder, TItem>;

    setFoldersComputed(compute: () => TFolder[]): void;
    setItemsComputed(compute: () => TItem[]): void;
  }
}

TreeDataSource.prototype.stateFromParams = function(folderParam?: string, itemParam?: string) {

  const locationService = inject(RouteService);

  const folderId$ = folderParam ? locationService.getParam$(folderParam) : undefined;

  const itemId$ = itemParam ? locationService.getParam$(itemParam) : undefined;

  return new TreeState(this, folderId$, itemId$);
}

TreeDataSource.prototype.itemStateFromParams = function(itemParam?: string) {

  const locationService = inject(RouteService);

  const itemId$ = itemParam ? locationService.getParam$(itemParam) : undefined;

  return new TreeItemState(this, itemId$);
}

TreeDataSource.prototype.createState = function() {
  return new TreeState(this);
}

TreeDataSource.prototype.createItemState = function() {
  return new TreeItemState(this);
}

TreeDataSource.prototype.createSelection = function() {
  return new TreeSelection(this);
}

TreeDataSource.prototype.createRange = function() {
  return new TreeRange(this);
}

TreeDataSource.prototype.setFoldersComputed = function<TFolder extends WithId>(compute: () => TFolder[]) {
  assertInInjectionContext(TreeDataSource.prototype.setFoldersComputed);
  assertNotInReactiveContext(TreeDataSource.prototype.setFoldersComputed);
  effect(() => {
    const values = compute();
    untracked(() => this.setFolders(values));
  });
}

TreeDataSource.prototype.setItemsComputed = function<TItem extends WithId>(compute: () => TItem[]) {
  assertInInjectionContext(TreeDataSource.prototype.setItemsComputed);
  assertNotInReactiveContext(TreeDataSource.prototype.setItemsComputed);
  effect(() => {
    const values = compute();
    untracked(() => this.setItems(values));
  });
}
//</editor-fold>

export {};
