import {Injectable} from "@angular/core";
import {DialogService} from "@juulsgaard/ngx-material";
import {FormConfirmService, WarningDialog} from "@juulsgaard/ngx-forms-core";

@Injectable()
export class DialogFormConfirmService extends FormConfirmService {

  constructor(private dialogs: DialogService) {
    super();
  }

  confirmDelete(data: WarningDialog): Promise<boolean> {
    return this.dialogs.delete(data.title, data.text, {deleteText: data.btnText});
  }

  confirmSubmit(data: WarningDialog): Promise<boolean> {
    return this.dialogs.confirm(data.title, data.text, {confirmText: data.btnText});
  }

}
