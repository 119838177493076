<div class="files" *ngIf="uploadService.uploads.length">
    <div class="file" *ngFor="let file of uploadService.uploads">
        <div class="name">
            <b>{{file.name}}:</b>
            <i>{{file.size | fileSize}}</i>
        </div>
        <mat-progress-bar [class.success]="file.succeeded$ | async"
                          [class.failed]="file.failed$ | async"
                          color="primary"
                          [mode]="(file.processing$ | async) ? 'buffer' : 'determinate'"
                          [value]="file.progress$ | async">
        </mat-progress-bar>
    </div>
</div>
