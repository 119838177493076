
declare global {
  interface Set<T> {
    toArray(): T[];
    clone(): Set<T>;
    toggle(item: T, state?: boolean): boolean;
  }
}

Set.prototype.toArray = function<T> (this: Set<T>) {
  return Array.from(this);
}

Set.prototype.clone = function<T>(this: Set<T>) {
  return new Set<T>(this);
}

Set.prototype.toggle = function<T>(this: Set<T>, item: T, state?: boolean) {
  if (this.has(item)) {
    if (state === true) return false;
    this.delete(item);
    return true;
  }

  if (state === false) return false;
  this.add(item);
  return true;
}

export {};
