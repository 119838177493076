import {inject, LOCALE_ID, Provider, signal, Signal, WritableSignal} from "@angular/core";
import dayjs from "dayjs";

export class LocaleService {

  static Provide(): Provider[] {
    return [
      {provide: LocaleService, useValue: new LocaleService()},
      {provide: LOCALE_ID, useFactory: () => inject(LocaleService).angularLocale()}
    ]
  }

  private static defaultLocale = 'en';
  private static baseUrl = document.baseURI.replace(/\/+$/, '');

  readonly language: string;

  private _angularLocale: WritableSignal<string>;
  readonly angularLocale: Signal<string>;
  readonly _angularLoaded = signal(false);
  readonly angularLoaded = this._angularLoaded.asReadonly();

  private _dayjsLocale: WritableSignal<string>;
  readonly dayjsLocale: Signal<string>;
  readonly _dayjsLoaded = signal(false);
  readonly dayjsLoaded = this._angularLoaded.asReadonly();

  constructor() {

    this.language = navigator.languages?.[0] ?? navigator.language ?? LocaleService.defaultLocale;

    this._angularLocale = signal(this.language);
    this.angularLocale = this._angularLocale.asReadonly();

    this._dayjsLocale = signal(this.language);
    this.dayjsLocale = this._dayjsLocale.asReadonly();

    this.loadAngularLocale(this.language);
    this.loadDayjsLocale(this.language.toLowerCase());
  }

  //<editor-fold desc="Angular">
  private loadAngularLocale(locale: string) {
    import(/* @vite-ignore */`${LocaleService.baseUrl}/locales/angular/${locale}.js`).then(
      () => {
        console.debug(`Loaded Angular Locale:`, locale);
        this._angularLoaded.set(true);
      },
      e => {
        const baseLocale = LocaleService.getBaseLocale(locale);

        if (!baseLocale) {
          console.error(e, `Failed to load Angular locale: "${locale}"`);
          this._angularLocale.set(LocaleService.defaultLocale);
          this._angularLoaded.set(true);
          return;
        }

        console.warn(`Failed to load Angular locale: "${locale}", now trying: "${baseLocale}"`);
        this._angularLocale.set(baseLocale);
        this.loadAngularLocale(baseLocale);
      }
    );
  }
  //</editor-fold>

  //<editor-fold desc="Dayjs">
  private loadDayjsScript(locale: string) {
    const script = document.createElement('script');
    script.src = `${LocaleService.baseUrl}/locales/dayjs/${locale}.js`;

    const promise = new Promise<Event>((resolve, reject) => {
      script.onload = e => resolve(e);
      script.onerror = e => reject(e);
      document.body.appendChild(script);
    });

    promise.finally(() => script.remove());
    return promise;
  }

  private loadDayjsLocale(locale: string) {
    if (dayjs.locale() === locale) {
      console.debug(`Dayjs locale already loaded:`, locale);
      this._dayjsLoaded.set(true);
      return;
    }

    // add dayjs to window so locales can be loaded dynamically
    if (!('dayjs' in window)) (window as any)['dayjs'] = dayjs;

    this.loadDayjsScript(locale).then(
      () => {
        dayjs.locale(locale);

        if (dayjs.locale() !== locale) {
          this.failedDayjs(locale, Error(`Locale wasn't loaded properly`));
          return;
        }

        console.debug(`Loaded Dayjs Locale:`, locale);
        this._dayjsLoaded.set(true);
      },
      e => this.failedDayjs(locale, e)
    );
  }

  private failedDayjs(locale: string, error: Error) {
    const baseLocale = LocaleService.getBaseLocale(locale);

    if (!baseLocale) {
      console.error(error, `Failed to load Dayjs locale: "${locale}"`);
      this._dayjsLocale.set(LocaleService.defaultLocale);
      this._dayjsLoaded.set(true);
      return;
    }

    console.warn(`Failed to load Dayjs locale: "${locale}", now trying: "${baseLocale}"`);
    this._dayjsLocale.set(baseLocale);
    this.loadDayjsLocale(baseLocale);
  }
  //</editor-fold>

  private static getBaseLocale(locale: string) {
    const split = locale.split('-');
    if (split.length < 2) return undefined;
    return split.at(0);
  }
}
